<template>
  <div style="width:100%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="80px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">流程名称</div>
            <el-input v-model="flowName" style="width:100%;" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">节点名称<font color="red">*</font></div>
            <el-input v-model="form.name" placeholder="节点名称" style="width:100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">审核人员<font color="red">*</font></div>
            <el-input v-model="personName" style="width:70%;" placeholder="审核人员" readonly />
            <el-button size="small" type="primary" style="margin-left:10px;" @click="onButtonEdit()"> 添加 </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">排序号<font color="red">*</font></div>
            <el-input-number v-model="form.sortNum" :min="1" style="width:100%;" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input v-model="form.remark" type="textarea" :autosize="{ minRows: 2, maxRows: 2}" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="text-align:center;">
      <el-button type="primary" @click="save()">保 存</el-button>
      <el-button type="danger" @click="closePage">取消</el-button>
    </div>

    <el-dialog :close-on-click-modal="false" :visible.sync="xgfVisible" append-to-body width="1000px" title="选择人员">
      <xgf v-if="xgfVisible" ref="xgf" :visible.sync="xgfVisible" @sendmsg="getXgfMsg" />
    </el-dialog>
  </div>
</template>

<script>
import { getInfo, editData } from '@/api/flowNode'
import { Message } from 'element-ui'

import Xgf from './selectUser' // eslint-disable-line no-unused-vars

export default {
  components: { Xgf },
  inject: ['getList'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        sortNum: 1
      },
      xgfVisible: false,
      flowName: '',
      personName: ''
    }
  },
  methods: {
    getDetailed() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      getInfo({ code: this.proid }).then(response => {
        this.form = response.data
        this.flowName = response.data.flowName
        this.personName = response.data.personName

        loading.close()
      }).catch(function() {
        loading.close()
      })
    },
    onButtonEdit() {
      this.xgfVisible = true
      this.$nextTick(() => {
        this.$refs.xgf
      })
    },
    // 获取选择的相关方
    getXgfMsg(data) {
      this.form.personId = ''
      this.personName = ''
      if (data !== null) {
        this.form.personId = data.id
        this.personName = data.nickName
      }
    },
    save() { // 新增
      if (!this.form.name) {
        this.$message.error('请填写节点名称')
        return false
      } else if (!this.form.personId) {
        this.$message.error('请选择审核人员')
        return false
      } else if (!this.form.sortNum) {
        this.$message.error('排序号不能为空')
        return false
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        editData(this.form).then(response => {
          Message({
            message: '编辑成功',
            type: 'success',
            duration: 5 * 1000
          })

          this.$emit('update:visible', false)
          loading.close()
          this.getList()
        }).catch(response => {
          loading.close()
          this.getList()
        })
      }
    },
    closePage() {
      this.$emit('update:visible', false)
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
