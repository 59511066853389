<template>

  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="queryPage.code" class="filter-item" placeholder="流程代码" style="width: 150px" clearable />
      <el-input v-model="queryPage.name" class="filter-item" placeholder="流程名称" style="width: 150px" clearable />

      <el-button class="filter-item" icon="el-icon-search" type="primary" @click="search()">
        查询
      </el-button>
    </div>

    <el-table v-loading="listLoading" :data="data" :height="tableHeight" style="width: 100%; overflow:auto" border @selection-change="handleSelectionChange">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column align="center" label="操作" width="280px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status==='1'" type="primary" size="small" @click="handleEdit(scope)">节点管理</el-button>
          <el-button v-if="scope.row.status==='1'" type="danger" size="small" @click="handleChange(scope, '2')">禁用</el-button>
          <el-button v-if="scope.row.status==='2'" type="primary" size="small" @click="handleChange(scope, '1')">启用</el-button>
        </template>
      </el-table-column>
      <el-table-column align="right" label="流程代码" header-align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column align="right" label="流程名称" header-align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="状态" header-align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ getDicName(scope.row.status,'YW_BASE_STATUS') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人员" align="left" header-align="center">
        <template slot-scope="scope">
          {{ scope.row.createUserName }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="right" header-align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :close-on-click-modal="false" :visible.sync="flowNodeVisible" append-to-body width="1200px" :title="'节点管理'">
      <flowNode v-if="flowNodeVisible" ref="flowNode" :flowid="flowid" :flowna="flowna" :visible.sync="flowNodeVisible" />
    </el-dialog>

    <pagination v-show="total>0" style="margin-top:10px;padding:0;" :total="total" :page.sync="queryPage.page" :limit.sync="queryPage.limit" @pagination="loadList1" />
  </div>
</template>

<script>
import { getPage, change } from '@/api/flowManage'
import { getDictListByCode } from '@/api/dict'
import { Message, MessageBox } from 'element-ui'
import { tableHeight } from '@/utils/tableHeight'
import Pagination from '@/components/Pagination'

import FlowNode from '../flowManage/flowNode' // eslint-disable-line no-unused-vars

export default {
  components: { Pagination, FlowNode },
  mixins: [tableHeight],
  provide() {
    return {
      getList: this.loadList
    }
  },
  data() {
    return {
      listLoading: false,
      queryPage: {
        page: 1,
        limit: 20
      },
      total: 0,
      flowid: '',
      flowna: '',
      data: [],
      types: [],
      statuss: [],
      flowNodeVisible: false,
      exportVisible: false,
      multipleSelection: []
    }
  },
  created() {
    this.loadList1()
    this.getDictList('YW_DATABASE_TYPE')
    this.getDictList('YW_BASE_STATUS')
  },
  methods: {
    search() { // 查询
      this.loadList()
    },
    loadList() { // 加载列表
      this.data = []
      this.listLoading = true

      this.queryPage.state = ''
      getPage(this.queryPage).then(response => {
        const { data } = response

        this.data = data.records
        this.total = parseInt(data.total)
        this.listLoading = false
      }).catch(response => {
        this.listLoading = false
      })
    },
    loadList1() { // 加载列表
      this.data = []
      this.listLoading = true

      this.queryPage.state = '1'
      getPage(this.queryPage).then(response => {
        const { data } = response

        this.data = data.records
        this.total = parseInt(data.total)
        this.listLoading = false
      }).catch(response => {
        this.listLoading = false
      })
    },
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_BASE_STATUS') {
          this.statuss = response.data
        }
        if (code === 'YW_DATABASE_TYPE') {
          this.types = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_BASE_STATUS') {
        dict = this.statuss
      }
      if (flag === 'YW_DATABASE_TYPE') {
        dict = this.types
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    handleEdit(scope) {
      this.flowNodeVisible = true
      this.flowid = scope.row.id
      this.flowna = scope.row.name
      this.$nextTick(() => {
        this.$refs.flowNode
        this.$refs.flowNode.getDetailed()
      })
    },
    handleChange(scope, code) {
      var flagName = '禁用'
      if (code === '1') {
        flagName = '启用'
      }
      MessageBox.confirm('确认是否' + flagName + '?', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: flagName + '中......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        change({ code: scope.row.id, status: code }).then(response => {
          Message({
            message: flagName + '成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.loadList()
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
#app-contain{
    height:100%;
    width:100%;
  }
</style>
