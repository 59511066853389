<template>
  <div style="width:100%;height:auto; margin:0 auto;">
    <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>

    <el-table v-loading="listLoading" :data="data" height="460" style="width: 100%;margin-top:10px; overflow:auto" border @selection-change="handleSelectionChange">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column align="center" label="操作" width="150px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status==='1'" type="primary" size="small" @click="handleEdit(scope)">编辑</el-button>
          <el-button v-if="scope.row.status==='1'" type="danger" size="small" @click="handleChange(scope, '2')">禁用</el-button>
          <el-button v-if="scope.row.status==='2'" type="primary" size="small" @click="handleChange(scope, '1')">启用</el-button>
        </template>
      </el-table-column>
      <el-table-column align="left" label="节点名称" header-align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="审核人员" header-align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.personName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="状态" header-align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ getDicName(scope.row.status,'YW_BASE_STATUS') }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" header-align="center" label="备注" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人员" align="left" header-align="center" width="120">
        <template slot-scope="scope">
          {{ scope.row.createUserName }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="right" header-align="center" width="180">
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :close-on-click-modal="false" :visible.sync="dialogAddVisible" :show-close="false" append-to-body width="1200px" :title="'新增节点'" >
      <dialog-add v-if="dialogAddVisible" ref="dialogAdd" :proid="code" :visible.sync="dialogAddVisible" />
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :visible.sync="dialogEditVisible" :show-close="false" append-to-body width="1200px" :title="'编辑节点'" >
      <dialog-edit v-if="dialogEditVisible" ref="dialogEdit" :proid="code" :visible.sync="dialogEditVisible" />
    </el-dialog>

    <pagination v-show="total>0" style="margin-top:10px;padding:0;" :total="total" :page.sync="queryPage.page" :limit.sync="queryPage.limit" @pagination="loadList1" />
  </div>
</template>

<script>
import { getPage, change } from '@/api/flowNode'
import { getDictListByCode } from '@/api/dict'
import { Message, MessageBox } from 'element-ui'
import { tableHeight2 } from '@/utils/tableHeight'
import Pagination from '@/components/Pagination'

import DialogAdd from '../flowManage/addNode' // eslint-disable-line no-unused-vars
import DialogEdit from '../flowManage/editNode' // eslint-disable-line no-unused-vars

export default {
  components: { Pagination, DialogAdd, DialogEdit },
  mixins: [tableHeight2],
  props: { // 第二种方式
    flowid: {
      type: String,
      required: true
    },
    flowna: {
      type: String,
      required: true
    }
  },
  provide() {
    return {
      getList: this.loadList
    }
  },
  data() {
    return {
      listLoading: false,
      queryPage: {
        page: 1,
        limit: 20
      },
      total: 0,
      code: '',
      data: [],
      statuss: [],
      dialogAddVisible: false,
      dialogEditVisible: false,
      exportVisible: false,
      multipleSelection: []
    }
  },
  created() {
    this.loadList1()
    this.getDictList('YW_BASE_STATUS')
  },
  methods: {
    search() { // 查询
      this.loadList()
    },
    loadList() { // 加载列表
      this.data = []
      this.listLoading = true

      this.queryPage.state = ''
      getPage(this.queryPage).then(response => {
        const { data } = response

        this.data = data.records
        this.total = parseInt(data.total)
        this.listLoading = false
      }).catch(response => {
        this.listLoading = false
      })
    },
    loadList1() { // 加载列表
      this.data = []
      this.listLoading = true

      this.queryPage.state = '1'
      this.queryPage.flowId = this.proid
      getPage(this.queryPage).then(response => {
        const { data } = response

        this.data = data.records
        this.total = parseInt(data.total)
        this.listLoading = false
      }).catch(response => {
        this.listLoading = false
      })
    },
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_BASE_STATUS') {
          this.statuss = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_BASE_STATUS') {
        dict = this.statuss
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    handleAdd() {
      var form2 = {}
      form2.id = this.flowid
      form2.name = this.flowna
      this.dialogAddVisible = true
      this.code = form2
      this.$nextTick(() => {
        this.$refs.dialogAdd
      })
    },
    handleEdit(scope) {
      this.dialogEditVisible = true
      this.code = scope.row.id
      this.$nextTick(() => {
        this.$refs.dialogEdit
        this.$refs.dialogEdit.getDetailed()
      })
    },
    handleChange(scope, code) {
      var flagName = '禁用'
      if (code === '1') {
        flagName = '启用'
      }
      MessageBox.confirm('确认是否' + flagName + '?', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: flagName + '中......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        change({ code: scope.row.id, status: code }).then(response => {
          Message({
            message: flagName + '成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.loadList()
        })
      })
    }
  }
}
</script>
