import request from '@/utils/request'

export function getPage(data) {
  return request({
    url: '/flowManage/getPage',
    method: 'post',
    data
  })
}

export function saveData(data) {
  return request({
    url: '/flowManage/saveData',
    method: 'post',
    data
  })
}

export function editData(data) {
  return request({
    url: '/flowManage/editData',
    method: 'post',
    data
  })
}

export function change(data) {
  return request({
    url: '/flowManage/change',
    method: 'post',
    params: data
  })
}

export function getInfo(data) {
  return request({
    url: '/flowManage/getInfo',
    method: 'get',
    params: data
  })
}
