<template>
  <div style="width:100%;height:auto; margin:0 auto;">
    <div style="padding:15px 0 0 0;text-align:center;margin-bottom:20px;">
      <el-input v-model="listQuery.zjName" placeholder="姓名" clearable style="width: 150px;" size="small" />

      <el-button class="filter-item" type="primary" icon="el-icon-search" size="small" style="margin-left: 10px" @click="getList">查询</el-button>
    </div>
    <div>
      <el-table ref="multipleTable" v-loading="screenLoading" :data="zjList" border fit highlight-current-row height="300" style="width: 100%;" @current-change="handleCurrentChange">
        <el-table-column label="序号" type="index" align="center" header-align="center" width="80" />
        <el-table-column label="姓名" align="left" header-align="center">
          <template slot-scope="scope">
            <span v-html="scope.row.nickName" />
          </template>
        </el-table-column>
        <el-table-column label="角色" align="left" header-align="center">
          <template slot-scope="scope">
            <span v-html="scope.row.roleName" />
          </template>
        </el-table-column>
        <el-table-column label="邮箱" align="left" header-align="center">
          <template slot-scope="scope">
            <span v-html="scope.row.email" />
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" style="margin-top:10px;padding:0;" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" align="right" @pagination="getList" />
    </div>
    <span slot="footer" class="dialog-footer">
      <div style="text-align:center;margin-top:20px;">
        <el-button type="primary" @click="onSave">保 存</el-button>
        <el-button type="danger" class="quxiao_btn" @click="closePage()">取消</el-button>
      </div>
    </span>
  </div>
</template>

<script>

import { getList } from '@/api/user'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

export default {
  components: { Pagination },
  data() {
    return {
      name: '',
      zjList: [],
      screenLoading: false,
      total: 1,
      listQuery: {
        page: 1,
        limit: 10,
        zjName: null
      },
      list: [],
      currentRow: null
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.zjList = []

      this.screenLoading = true
      getList(this.listQuery).then(response => {
        this.zjList = response.data.records
        this.total = parseInt(response.data.total)
        this.screenLoading = false
      }).catch(response => {
        this.screenLoading = false
      })
    },
    closePage() {
      this.$emit('update:visible', false)
    },
    handleCurrentChange(val) {
      this.currentRow = val
    },
    onSave() {
      this.$emit('update:visible', false)
      this.$emit('sendmsg', this.currentRow)
    }
  }
}
</script>
<style scoped>
span{
  cursor:default;
}
.collapse-title{
  font-size: 16px;
  color:#1196EE;
}
.table{
  border-collapse: separate;
  border-spacing: 10px 10px;
}
.title-name{
  width: 200px;
  background:#eeeeee;
  text-align: center;
  vertical-align: middle;
}
.table-content{
   width: 200px;
}
</style>
